<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!---BOC:[header]-->
    <BreadHeader :title="modelName">
      <v-btn
        :disabled="!model.delete"
        text
        class="mx-1"
        color="error"
        :to="{
          name: 'PageServiceModelDelete',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: model.key,
            modelId: modelId,
          },
        }"
        >Delete</v-btn
      >
      <v-btn
        :disabled="!model.edit"
        text
        color="primary"
        :to="{
          name: 'PageServiceModelEdit',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: model.key,
            modelId: modelId,
          },
        }"
        >Edit</v-btn
      >
    </BreadHeader>
    <!--EOC-->
    <!---BOC:[error]-->
    <AError :api="api" />
    <!--EOC-->
    <!---BOC:[body]-->
    <v-card>
      <ATab :tab="tab" />
      <v-tabs-items v-if="model && model.key" v-model="tab.current">
        <v-tab-item
          v-for="tabItem in tab.tabs"
          :value="tabItem.key"
          :key="tabItem.key"
        >
          <!-- BOC:[custom tab] -->
          <component
            v-if="tabItem.component"
            :is="tabItem.component"
          ></component>
          <!-- EOC -->
          <BreadDataView
            v-else-if="tabItem.key == 'info'"
            :api="api"
            :modelKey="model.key"
            :table="table"
            :viewableColumns="table.headers"
            operation="read"
          />
          <BreadTabChildV2
            v-else-if="
              me &&
              tabItem.dataType == 'model' &&
              tabItem.relationship == 'child'
            "
            :tabProperties="tabItem"
            :parent="me"
            :parentModel="model"
            :childModelKey="tabItem.modelKey"
          />
          <BreadTabIntermediate
            v-else-if="
              me &&
              tabItem.dataType == 'model' &&
              tabItem.relationship == 'intermediate'
            "
            :tabProperties="tabItem"
            :parent="me"
            :parentModel="model"
            :childModelKey="tabItem.modelKey"
            :intermediateChildModelKey="tabItem.intermediateChildModelKey"
          />
          <BreadTabChild
            v-else-if="me"
            :parent="me"
            :parentModel="model"
            :childModelKey="tabItem.key"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!--EOC-->
    <!---BOC:[note]-->
    <ADevNote
      :text="[
        'Layout',
        ['✓ Show breadcrumbs', '✓ Show title'],
        'API',
        ['✓ Show loading', '✓ Show error', '✓ Show no items'],
        'Tabs',
        [
          '✗ Go to target tab if has tab query in url',
          '✗ Update url query upon changing tab',
          '✓ Enable customized tab',
        ],
      ]"
    />
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
//BOC:[tab]
import Tab from "@/objects/tab";
//EOC
//BOC:[table]
import Table from "@/objects/table";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[model]
    model: new Model(),
    modelId: null,
    modelName: null,
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
    //BOC:[tab]
    tab: new Tab(),
    //EOC
    //BOC:[table]
    table: new Table(),
    //EOC
    //BOC:[me]
    me: null,
    //EOC
  }),
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    this.modelId = this.$route.params.modelId;
    this.modelName = `${this.model.name.singular} ${this.modelId}`;
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC
    //BOC:[tab]
    var tabs = this.model.read.tabs
      ? this.$_.cloneDeep(this.model.read.tabs)
      : [];
    tabs.push({
      key: "info",
      label: "Info",
    });
    this.tab.setTabs(tabs);
    //EOC
    //BOC:[table]
    this.table.setTable(this.model.read.table);
    if (this.model.read === true) this.table.setTable(this.model.browse.table);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumb.addForOperationRead({
      services: this.services,
      model: this.model,
      modelId: this.$route.params.modelId,
    });
    //EOC
    //BOC:[api]
    this.api.setUrl(
      `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/${this.modelId}`
    );
    this.api.setCallbackCompleted((resp) => {
      //BreadDataView component is also used in browse operation. It only process array.
      this.api.setData([resp]);
      this.api.setInfo({
        page: 1,
        itemsPerPage: 1,
        totalPages: 1,
        totalItems: 1,
      });
      if (this.model.column && this.model.column.text) {
        this.modelName = resp[this.model.column.text];
      }
      else if (resp.name) {
        this.modelName = resp.name;
      }
      //BOC:[me]
      this.me = resp;
      //EOC
    });
    this.api.fetch();
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>