var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ABreadcrumb',{attrs:{"items":_vm.breadcrumb.items}}),_c('BreadHeader',{attrs:{"title":_vm.modelName}},[_c('v-btn',{staticClass:"mx-1",attrs:{"disabled":!_vm.model.delete,"text":"","color":"error","to":{
          name: 'PageServiceModelDelete',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: _vm.model.key,
            modelId: _vm.modelId,
          },
        }}},[_vm._v("Delete")]),_c('v-btn',{attrs:{"disabled":!_vm.model.edit,"text":"","color":"primary","to":{
          name: 'PageServiceModelEdit',
          params: {
            serviceKey: this.model.serviceKey,
            modelKey: _vm.model.key,
            modelId: _vm.modelId,
          },
        }}},[_vm._v("Edit")])],1),_c('AError',{attrs:{"api":_vm.api}}),_c('v-card',[_c('ATab',{attrs:{"tab":_vm.tab}}),(_vm.model && _vm.model.key)?_c('v-tabs-items',{model:{value:(_vm.tab.current),callback:function ($$v) {_vm.$set(_vm.tab, "current", $$v)},expression:"tab.current"}},_vm._l((_vm.tab.tabs),function(tabItem){return _c('v-tab-item',{key:tabItem.key,attrs:{"value":tabItem.key}},[(tabItem.component)?_c(tabItem.component,{tag:"component"}):(tabItem.key == 'info')?_c('BreadDataView',{attrs:{"api":_vm.api,"modelKey":_vm.model.key,"table":_vm.table,"viewableColumns":_vm.table.headers,"operation":"read"}}):(
              _vm.me &&
              tabItem.dataType == 'model' &&
              tabItem.relationship == 'child'
            )?_c('BreadTabChildV2',{attrs:{"tabProperties":tabItem,"parent":_vm.me,"parentModel":_vm.model,"childModelKey":tabItem.modelKey}}):(
              _vm.me &&
              tabItem.dataType == 'model' &&
              tabItem.relationship == 'intermediate'
            )?_c('BreadTabIntermediate',{attrs:{"tabProperties":tabItem,"parent":_vm.me,"parentModel":_vm.model,"childModelKey":tabItem.modelKey,"intermediateChildModelKey":tabItem.intermediateChildModelKey}}):(_vm.me)?_c('BreadTabChild',{attrs:{"parent":_vm.me,"parentModel":_vm.model,"childModelKey":tabItem.key}}):_vm._e()],1)}),1):_vm._e()],1),_c('ADevNote',{attrs:{"text":[
        'Layout',
        ['✓ Show breadcrumbs', '✓ Show title'],
        'API',
        ['✓ Show loading', '✓ Show error', '✓ Show no items'],
        'Tabs',
        [
          '✗ Go to target tab if has tab query in url',
          '✗ Update url query upon changing tab',
          '✓ Enable customized tab' ] ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }